/* Nav */
nav {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.333333333;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    ul {
        list-style-type: none;
        overflow: hidden;
        margin: 0;
        padding: 0;
    }

    li {
        margin: 0;
        padding: 0;
        &::before { content: none; }
        a {
            color: $color-dark;
            display: block;
            margin: 15px 15px;
        }
    }
}

nav li a {
    &.button {
        color: $color-dark;
    }
}

.menu .menu-items {
    align-items: center;
    display: block;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: visible;

    @media only screen and (min-width: $nav-page-width) {
        display: flex;
        max-width: $page-width;
        margin: 0 auto;
    }

    @media only screen and (max-width: $nav-page-width - 1px) {
        background-color: $color-mid;
    }
}

.menu {
    .menu-item {
        border: $border-width solid $black;
        color: $color-dark;
        display: block;
        padding: 12px;
        margin: 20px 0;
        position: relative;
        text-align: center;
        text-decoration: none;
        text-transform: lowercase;
        z-index: 1;
    }

    @media only screen and (min-width: $nav-page-width) {
        .menu-item {
            background-color: transparent !important;
            border: 0;
            margin: 15px;
            padding: 12px 0;
        }

        .menu-item:hover,
        li.active .menu-item {
            box-shadow: inset 0 -3px 0 0 $black;
        }
    
        .menu-item {
            color: $color-dark;
        }
        .menu-item:hover,
        li.active .menu-item {
            color: $color-dark;
        }
    }
}

.menu .menu-item:focus,
.menu .menu-item:focus-within {
    // box-shadow: 0 0 0 3px rgba($focus-color, 1);
    outline: $focus-color auto 1px;
    border-radius: 0;
}

.menu .menu-items {
    padding: 60px $col_margin;
    position: relative;

    &::before,
    &::after {
        border-left: 6px solid $color-dark;
        content: "";
        display: block;
        position: absolute;
        left: ($col_margin * 2);
        bottom: 0;
        top: 0;
    }
    
    &::after {
        left: auto;
        right: ($col_margin * 2);
    }

    @media only screen and (min-width: $nav-page-width) {
        padding: 0;

        &::before,
        &::after {
            content: none;
        }
    }
}

.menu-item.yellow {
    background-color: $color-yellow;
}

.menu-item.green {
    background-color: #49af86;
}

.menu-item.greenblue {
    background-color: $color-greenblue;
}

.menu-item.purple {
    background-color: $color-purple;
}

.menu-item.orange {
    background-color: $color-orange;
}

.menu-item.white {
    background-color: $color-bright;
}

.closed .menu .menu-dropdown {
    display: none;

    @media only screen and (min-width: $nav-page-width) {
        display: block;
        min-height: 70px;
    }
}

.opened .menu .menu-dropdown {
    border-top: 6px solid $color-dark;

    @media only screen and (min-width: $nav-page-width) {
        border-top: none;
    }
}

.menu-button {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: $color-dark;
    display: flex;
    flex-flow: row-reverse;
    font-size: 18px;
    font-weight: 600;
    align-items: flex-end;
    margin: 0;
    padding: 0;
    text-align: center;
    height: 76px;
}

.menu-button-label {
    padding: 15px;
    line-height: 1;
}

@media only screen and (min-width: $nav-page-width) {
    .main-nav .menu-button {
        display: none;
    }
}

/* Nav icon  */
.nav-icon {
    margin: 0 auto 16px;
    width: 26px;

    &:after, 
    &:before, 
    & div {
        background-color: #fff;
        content: '';
        display: block;
        height: 6px;
        // transition: all .2s ease-in-out;
    }
    &:before {
        background-color: $color-dark;
        margin-bottom: 6px;
    }
    & div {
        background-color: $color-dark;
        margin-bottom: 6px;
    }
    &:after {
        background-color: $color-dark;
    }
}

.opened .nav-icon {
    &:before {
        background-color: transparent;
    }

    & div {
        background-color: transparent;
    }

    &:after {
        margin-bottom: 6px;
    }
}

.external-menu {
    align-items: center;
    font-weight: 400;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
}

.external-menu .break {
    flex-basis: 100%;
}

.external-menu .menu-item {
    margin-bottom: 32px;
}

.external-menu .menu-item + .menu-item,
.external-menu .break + .menu-item {
    margin-left: 25px;
}

@media only screen and (min-width: $page-width) {
    .external-menu {
        justify-content: flex-start;
    }

    .external-menu .break {
        flex-basis: 0;
    }
}

.footer-menu {
    font-weight: 400;
}

.footer-menu .menu-item {
    display: block;
    font-size: 1.3rem;
    margin: 2.3rem 0;
}