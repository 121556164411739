.alert {
    background-color: $color-dark;
    color: $color-light;
    overflow: hidden;
    padding: 30px $col_margin;
}

.alert .inner {
    max-width: 810px;
    margin-left: auto;
    margin-right: auto;
}

.alert p:last-child {
    margin-bottom: 0;
}

.alert .alert-footer {
    padding-top: 20px;
    text-align: center;
}

.alert .alert-footer .btn {
    margin: 0 auto;
}

body .alert .btn.btn-light:hover {
    border-color: $color-light;
}