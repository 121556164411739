$border-color: $color-dark;
$border-width: 3px;
$menu-page-width: $viewport-medium;
$menu-page-width-column: 1180px;

.grid {
    max-width: $container-width;
    margin: 0 auto;
}

@media only screen and (min-width: $menu-page-width-column) {
    .menu-bar-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .menu-resto-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

.menu-category {
    position: relative;
}

.menu-category.menu-category-resto {
    background-color: $color-light;
}

.menu-category::before {
    border-bottom: 3px solid $color-dark;
    content: "";
    display: block;
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    z-index: 1;
}

@media only screen and (min-width: $menu-page-width) {
    .menu-category::before {
        background-color: $color-light;
        border-bottom: 3px solid $color-dark;
        content: "";
        display: block;
        position: absolute;
        height: calc(1.8rem + 25px);
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }
}

.grid-item--dark ~ .grid-item--dark .menu-category::before {
    background-color: transparent;
}

.menu-category.menu-category--batteliek {
    background-color: $color-light;
}

.item-info {
    line-height: 1.2;
    font-size: 1.5rem;
    font-weight: 500;
}

.item-info p {
    margin: 0;
}

.item-info p + p {
    margin-top: .5em;
}

.item-description strong {
    color: $color-turq-dark;
    font-weight: 500;
}

.menu-resto-category .item-info {
    font-size: 1.5rem;
}

.batteliek-cocktails .item-info {
    font-size: 1.5rem;
}

.menu-category .item-info strong {
    font-family: $title-font;
    font-size: 1.5rem;
    font-weight: 400;
}

.item-name {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    font-family: $base-font;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.25;
    margin: 0;
}

@media only screen and (min-width: $menu-page-width) {
    .item-name {
        justify-content: flex-start;
    }
}

.item-name em {
    color: $color-turq-dark;
    display: inline-block;
    font-family: $title-font;
    font-weight: 400;
    font-size: 1.2rem;
    font-style: normal;
    margin-left: 20px;
    padding: 4px 0 0;
    text-transform: uppercase;
}

.item-title {
    align-items: center;
    display: flex;
    padding-right: 10px;
}

.allergens {
    align-items: center;
    display: flex;
    list-style-image: none;
    list-style-type: none;
    margin: 0 5px 0 0;
    padding: 0;
}

.allergens li {
    display: block;
    margin: 0 6px 0 0;
    position: relative;
    width: 22px;
    height: 22px;
}
.allergens li span {
    display: none;
}

.allergens li:after {
    content: "";
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    background-image: $svg-icon-egg;
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center;
    width: 22px;
    height: 22px;
}

.allergens li.gluten:after {
    background-image: $svg-icon-gluten;
}

.allergens li.lupine:after {
    background-image: $svg-icon-lupin;
}

.allergens li.lactose:after {
    background-image: $svg-icon-milk;
}

.allergens li.mosterd:after {
    background-image: $svg-icon-mustard;
}

.allergens li.noten:after {
    background-image: $svg-icon-nuts;
}

.allergens li.pinda:after {
    background-image: $svg-icon-peanuts;
}

.allergens li.schaaldieren:after {
    background-image: $svg-icon-crustaceans;
}

.allergens li.selderij:after {
    background-image: $svg-icon-celery;
}

.allergens li.sesamzaad:after {
    background-image: $svg-icon-sesame;
}

.allergens li.soja:after {
    background-image: $svg-icon-soja;
}

.allergens li.vis:after {
    background-image: $svg-icon-fish;
}

.allergens li.weekdieren:after {
    background-image: $svg-icon-molucs;
}

.allergens li.zwaveloxide:after {
    background-image: $svg-icon-sulphite;
}

.item-name-label {
    border: 3px solid $color-dark;
    padding: 5px 10px;
}

.menu-category .item-name.item-name-label,
.menu-category.menu-category--batteliek .item-name-label {
    padding: 0;
    max-width: 170px;
}

.menu-category header {
    margin-top: 25px;
    padding: 0 14px 10px;
    position: relative;
    z-index: 2;
}

.menu-category header .category-title {
    text-align: center;
}

@media only screen and (min-width: $menu-page-width) {
    .menu-category header {
        align-items: center;
        display: grid;
        grid-template-columns: 1fr auto;
        margin-top: 0;
        padding: 25px 14px 10px;
        position: relative;
        z-index: 2;
    }

    .menu-category header .category-title {
        text-align: left;
    }
}

.menu-category .menu-items {
    position: relative;
    z-index: 3;
}

.menu-category .category-title h2,
.menu-resto-category .category-title h2 {
    background-color: $color-dark;
    color: $color-light;
    display: inline-block;
    line-height: 1.2;
    font-size: 1.8rem;
    margin: 0;
    padding: 6px 9px 2px;
    text-transform: uppercase;
}

.menu-category header table {
    display: block;
    margin: 20px auto;
    // width: 220px;
    width: 100%;
}

.menu-category header tbody {
    display: block;
}

@media only screen and (min-width: $menu-page-width) {
    .menu-category header table {
        margin: 0;
    }
}

.menu-category header table td {
    background-color: $color-light;
    border: 3px solid $color-dark;
    display: block;
    font-family: $title-font;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.2;
    padding: 6px 6px 2px;
    text-align: center;
    width: 68px;
}

.menu-category header table tr {
    display: flex;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    width: 100%;
}

@media only screen and (min-width: $menu-page-width) {
    .menu-category header table tr {
        align-items: end;
        justify-content: end;
    }
}

.menu-category .menu-item-inner {
    padding: 10px 14px;
}

@media only screen and (min-width: $menu-page-width) {
    .menu-category .menu-item-inner {
        display: flex;
        padding: 10px 14px;
    }

    .menu-category .menu-item-inner .col:first-child {
        flex: 1;
    }
}

.menu-resto-category .menu-item {
    border-bottom: 1px solid $color-mid;
}

.menu-resto-category .menu-item-inner {
    padding: 0 14px 2px;
}

@media only screen and (min-width: $menu-page-width) {
    .menu-resto-category .menu-item-inner {
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr auto;
        padding: 0 14px 2px;
    }
}

.menu-resto-category .menu-item-inner + .menu-item-inner {
    padding-top: 10px;
}

.batteliek-cocktails .col-description {
    font-size: 1.5rem;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    line-height: 1.27;
    text-align: center;
}

@media only screen and (min-width: $menu-page-width) {
    .batteliek-cocktails .col-description {
        text-align: left;
    }
}

.menu-item-bar .col-name {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    margin: 10px 0;
}

.menu-item .col-name .item-info {
    margin: 10px 0 0;
    text-align: center;
}

@media only screen and (min-width: $menu-page-width) {
    .menu-item-bar .col-name {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin: 0;
    }

    .menu-item .col-name .item-info {
        margin: 0;
        text-align: left;
        flex: 1;
    }
}

.tasting-set .col-name {
    font-size: 2.7rem;
    font-weight: 700;
}

.tasting-set .col-name h3 {
    margin: 0;
    line-height: 1.27;
}

.menu-resto-category .col-name h3 {
    margin: 10px 0 5px;
}

.batteliek-cocktails .item-info {
    margin: 5px 0;
}

.menu-resto-category .item-info {
    text-align: center;
}

.menu-resto-category .item-name + .item-info {
    margin-top: -5px;
}

@media only screen and (min-width: $menu-page-width) {
    .menu-resto-category .item-info {
        text-align: left;
    }
}

.tasting-set .item-info {
    border: 3px solid $color-dark;
    font-family: $title-font;
    font-weight: 400;
    font-size: 1.5rem;
    padding: 6px 10px 3px;
    text-align: center;
}

.col-price {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
}

.tasting-set .col-price {
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-columns: 30% 30%;
    margin: 0 auto;
    max-width: 300px;
}

@media only screen and (min-width: $menu-page-width) {
    .col-price {
        width: auto;
    }

    .tasting-set .col-price {
        align-items: center;
        display: grid;
        grid-template-columns: 70% 30%;
        margin: 0;
        width: 220px;
    }
}

.menu-resto-category .col-price {
    display: block;
    padding: 5px 0;
}

.menu-item.bar-food .col-price {
    width: 68px;
    margin: 0 auto;
}

.price-table {
    display: block;
    margin: 10px auto;
}

.price-table tbody {
    display: block;
}

@media only screen and (min-width: $menu-page-width) {
    .price-table {
        margin: 0;
    }
}

.price-table tr {
    display: flex;
    justify-content: center;
    gap: 8px;
}

@media only screen and (min-width: $menu-page-width) {
    .price-table tr {
        justify-content: end;
    }
}

.price-table td,
.price-table th {
    width: 68px;
}

.price-table td {
    align-items: center;
    display: flex;
    font-family: $base-font;
    font-weight: 500;
    font-size: 1.5rem;
    justify-content: center;
    padding: 10px 0;
    text-align: center;
}

.price-table th:has(span) {
    border: 3px solid $color-dark;
    color: $color-dark;
    display: block;
    font-family: $title-font;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.2;
    padding: 6px 6px 2px;
    margin: 5px 0;
    text-align: center;
}

@media only screen and (min-width: $menu-page-width) {
    .price-table td {
        padding: 0 0 5px;
    }
}

.tasting-set .price {
    text-align: center;
}

.price {
    color: $color-subsub;
    font-size: 1.5rem;
    line-height: 1.277;
    text-align: center;
}

.sold-out {
    background-color: $color-subsub;
    color: $color-light;
    font-family: $title-font;
    font-size: 2rem;
    font-weight: 400;
    padding: 5px 10px 3px;
    text-align: center;
    width: 100%;
}

.menu-resto-category header {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0;
    position: relative;
}

@media only screen and (min-width: $menu-page-width) {
    .menu-resto-category header {
        justify-content: flex-start;
    }
}

.menu-resto-category header::before {
    border-bottom: 3px solid $color-dark;
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    z-index: 1;
    margin-top: -2px;
}

.menu-resto-category header .image {
    display: none;
}

@media only screen and (min-width: $menu-page-width) {
    .menu-resto-category header .image {
        display: block;
        margin-left: -2px;
        position: relative;
        z-index: 2;
    }
}

.menu-resto-category header .category-title {
    position: relative;
    z-index: 3;
}

.menu-legend {
    border: 6px solid $border-color;
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0 $gutter;
    padding: 1px;
    max-width: 892px;
    justify-content: center;
}

.menu-legend .icon {
    height: 40px;
    width: 40px;
}

.menu-legend li {
    padding: 10px 5px;
    width: 100px;
    text-align: center;
}

.menu-legend .label {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1;
    margin: 8px 0;
    text-align: center;
}

@media only screen and (min-width: $viewport-large) {
    .menu-legend {
        margin: 0 auto;
        padding: 20px;
    }

    .menu-legend li {
        width: 120px;
    }

    .menu-legend .icon {
        height: 65px;
        width: 65px;
    }
    
    .menu-legend .label {
        font-size: 1.5rem;
    }  
}