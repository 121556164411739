#main-header,
#age-verification header {
    background-color: $color-light;
    border-bottom: $border-width solid $color-dark;
    margin-bottom: 70px;
    overflow: visible;
    position: relative;
    z-index: z-index(navigation);
}

#main-header.opened {
    border-bottom: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
}

.single #main-header,
.page-legal #main-header {
    margin-bottom: 85px;   
}

@media only screen and (min-width: $nav-page-width) {
    #main-header.opened {
        border-bottom: $border-width solid $color-dark;
        display: block;
        height: auto;
        margin-bottom: 70px;
    }

    #main-header.closed,
    #main-header.opened {
        display: flex;
        flex-direction: column-reverse;
    }

    .single #main-header,
    .page-legal #main-header {
        margin-bottom: 160px; 
    }
}

#main-header .menu-button,
#main-header-compact .menu-button {
    position: absolute;
    top: 0;
    right: 0;
}

.site-name {
    display: flex;
    justify-content: center;
    min-height: 83px;
    pointer-events: none;
    position: relative;
    z-index: z-index(banner);

    @media only screen and (min-width: $nav-page-width) {
        min-height: 34px;
    }
}

.site-name a {
    bottom: -40px;
    display: block;
    margin: 0 auto;
    pointer-events: auto;
    position: absolute;
}

#main-header.opened .site-name .b,
.site-name .b {
    fill: $color-light;
}

.page-legal .site-name .b {
    fill: $color-light !important;
}

.site-name .logo-desktop {
    display: none;
}

@media only screen and (min-width: $nav-page-width) {
    .site-name .logo-desktop {
        display: block;
    }

    .site-name .logo-mobile {
        display: none;
    }
}

#main-header .dropdown {
    position: absolute;
    right: 0;
    top: 0;
}

/* Page Header */
.page-header {
    background-color: $color-light;
    border-bottom: $border-width solid $color-dark;
    margin-top: -70px;
    text-align: center;
}

.page-header.page-header-entry {
    padding-bottom: 24px;
    margin-top: 0;
    overflow: visible;
}

.page-header.page-header-entry .inner {
    padding-bottom: 0;
    padding-top: 0;
}

.page-header.page-header-entry .page-title {
    margin: .2em 0;
    padding: 0 20px;
}

.page-header.page-header-entry .top-title {
    position: relative;
    top: -25px;
}

/* Page Jobs */

.page-jobs .page-header p {
    position: relative;
    z-index: 1;
}

.page-jobs .page-header .drawing img {
    display: block;
}

.page-jobs .page-header .page-header-bottom {
    margin-bottom: -30px;
    position: relative;
    z-index: 2;
}

.page-jobs .page-header:has(.page-header-bottom) .drawing {
    position: relative;
    bottom: -30px;
}

@media only screen and (min-width: $viewport-x-large) {
    .page-header {
        font-size: 1.8rem;
    }

    .page-jobs .page-header {
        position: relative;
    }
    
    .page-jobs .page-header .drawing {
        position: absolute;
        left: 0;
        bottom: 0;
        margin: 0;
        width: calc((100vw - 700px) / 2);
    }
    
    .page-jobs .page-header .inner {
        position: static;
        max-width: 700px;
    }
}

@media only screen and (min-width: $viewport-xx-large) {
    .page-jobs .page-header .inner {
        max-width: 700px;
    }

}

@media only screen and (min-width: $page-width) {
    .page-header {
        font-size: 2.1rem;
    }
}

.page-header.compact {
    min-height: auto;
}

#age-verification .site-name {
    position: relative;
    transform: translateY(50%);
}

.page-header .inner {
    padding-bottom: 20px;
    padding-top: 65px;
}

@media only screen and (min-width: $viewport-x-large) {
    .page-header .inner {
        max-width: $container-width;
        margin: 0 auto;
    }
}

.page-header .page-title {
    margin: 0 0 -.2em;
}

.page-header-actions {
    padding-top: 70px;
    padding-bottom: 20px;
    margin-top: -90px;
    border-bottom: $border-width solid $color-dark;
}

@media only screen and (min-width: $viewport-x-large) {
    .page-header-actions {
        padding-top: 50px;
        padding-bottom: 20px;
        margin-top: -70px;
    }    
}

/* Theme Colours */
body[class*=" theme-"] #main-header.opened .site-name .b {
    fill: $color-mid;
}

body.theme-greenblue {
    .page-header {
        background-color: $color-greenblue;
    }

    #main-header .site-name .b {
        fill: $color-greenblue;
    }
}

.page-header.theme-yellow {
    background-color: $color-yellow;
}

body.theme-yellow {
    .page-header {
        background-color: $color-yellow;
    }

    #main-header .site-name .b {
        fill: $color-yellow;
    }
}

body.theme-orange {
    .page-header {
        background-color: $color-orange;
    }

    #main-header .site-name .b {
        fill: $color-orange;
    }
}

body.theme-light {
    .page-header {
        background-color: $color-light;
    }

    #main-header .site-name .b {
        fill: $color-light;
    }
}

body.theme-purple {
    .page-header {
        background-color: $color-purple;
    }

    #main-header .site-name .b {
        fill: $color-purple;
    }
}

body.theme-green {
    .page-header {
        background-color: $color-green;
    }

    #main-header .site-name .b {
        fill: $color-green;
    }
}

body.theme-peach {
    .page-header {
        background-color: $color-peach;
    }

    #main-header .site-name .b {
        fill: $color-peach;
    }
}

body.drink {
    .page-header {
        // background-color: var(--drink-base-color, $color-yellow);
        background-color: transparent;
    }

    #main-header .site-name .b {
        // fill: var(--drink-base-color, $color-yellow);
        fill: $color-light;
    }
}

@media only screen and (min-width: $page-width) {
    body.theme-greenblue {
        #main-header.opened .site-name .b {
            fill: $color-greenblue;
        }
    }
    
    body.theme-yellow {
        #main-header.opened .site-name .b {
            fill: $color-yellow;
        }
    }
    
    body.theme-orange {
        #main-header.opened .site-name .b {
            fill: $color-orange;
        }
    }
    
    body.theme-light {
        #main-header.opened .site-name .b {
            fill: $color-light;
        }
    }
    
    body.theme-purple {
        #main-header.opened .site-name .b {
            fill: $color-purple;
        }
    }

    body.theme-green {
        #main-header.opened .site-name .b {
            fill: $color-green;
        }
    }

    body.theme-peach {
        #main-header.opened .site-name .b {
            fill: $color-peach;
        }
    }

    body.drink {
        #main-header.opened .site-name .b {
            fill: var(--drink-base-color, $color-green);
        }
    }
}

/* Page Header Drawings */
.page-header.drawing {
    position: relative;
}

.page-header .inner {
    position: relative;
    z-index: 2;
}

.page-header .header-bottom {
    display: flex;
    justify-content: space-between;
}

.page-header .header-bottom img {
    max-width: 150%;
}

.page-header .header-bottom video {
    background-color: transparent;
    max-width: 150%;
}

.page-header .header-bottom .header-left,
.page-header .header-bottom .header-right {
    align-items: flex-end;
    display: flex;
    background-color: transparent;
}

.page-header .header-bottom .header-left {
    justify-content: flex-start;
}

.page-header .header-bottom .header-right {
    justify-content: flex-end;
}

@media only screen and (min-width: $viewport-x-large) {
    .page-header .header-bottom {
        position: absolute;
        bottom: 0;
        top: 0;
        width: 100%;
        z-index: 1;
    }

    .page-header.drawing {
        min-height: 380px;
    }

    .page-header .header-bottom img {
        max-width: 100%;
    }

    .page-header .header-bottom video {
        max-width: 100%;
    }

    .page-header.drawing .page-title {
        font-size: 8.2rem;
    }

    .page-header.drawing::after {
        content: none;
    }
    
    // .page-header.drawing .inner {
    .page-header .inner {
        max-width: 550px;
    }

    .page-header .header-bottom img {
        height: 320px;
    }

    .page-header .header-bottom video {
        height: 320px;
    }
    
    .page-header .header-bottom .header-left,
    .page-header .header-bottom .header-right {
        margin: 0;
    }
}

@media only screen and (min-width: $viewport-xx-large) {
    .page-header.drawing {
        min-height: 486px;
    }

    // .page-header.drawing .inner {
    .page-header .inner {
        max-width: 650px;
    }

    .page-header .header-bottom img {
        height: 480px;
    }

    .page-header .header-bottom video {
        height: 480px;
    }
}

@media only screen and (min-width: $viewport-xxx-large) {
    .page-header.drawing {
        min-height: 526px;
    }

    .page-header.drawing .page-title {
        font-size: 11.2rem;
    }

    // .page-header.drawing .inner {
    .page-header .inner {
        max-width: 700px;
    }

    .page-header .header-bottom img {
        height: 520px;
    }

    .page-header .header-bottom video {
        height: 520px;
    }
}

/* Drink page */
.drink .page-header {
    padding-top: 70px;
    padding-bottom: 20px;
}

@media only screen and (min-width: $viewport-x-large) {
    .drink .page-header {
        padding-top: 50px;
        padding-bottom: 20px;
    }    
}