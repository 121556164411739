#cc-main .cm {
    max-width: 420px;
}

#cc-main .cm__texts {
    padding: 30px 0 15px;
}

#cc-main .cm__title+.cm__desc {
    margin-top: 20px;
}

#cc-main .cm__desc, #cc-main .cm__title {
    padding: 0 30px;
}

#cc-main .cm__btn {
    border-width: 3px;
    font-family: $base-font;
    font-weight: 700;
    font-size: .9em;
}

#cc-main .cm__btns {
    padding: 20px 30px;
}

#cc-main .cm__btn+.cm__btn, #cc-main .cm__btn-group+.cm__btn-group {
    margin-top: 10px;
}

#cc-main .cm__links {
    padding: 10px 30px;
}

#cc-main .cm--inline .cm__btn+.cm__btn {
    margin-left: 10px;
}

#cc-main {
    --cc-bg: #{$color-bright};
    --cc-btn-border-radius: 0;
    --cc-btn-primary-bg: #{$color-yellow};
    --cc-btn-primary-color: #{$color-dark};
    --cc-btn-primary-hover-bg: #{$color-dark};
    --cc-btn-primary-hover-border-color: #{$color-dark};
    --cc-btn-primary-border-color: #{$color-dark};
    --cc-btn-secondary-color: #{$color-dark};
    --cc-btn-secondary-bg: #{$color-bright};
    --cc-btn-secondary-hover-bg: #{$color-dark};
    --cc-btn-secondary-border-color: #{$color-dark};
    --cc-btn-secondary-hover-color: #{$color-bright};
    --cc-btn-secondary-hover-border-color: #{$color-dark};

    --cc-footer-bg: #{$color-dark};
    --cc-footer-color: #{$color-bright};
    --cc-footer-hover-color: #{$color-dark};
    --cc-footer-border-color: #{$color-dark};
    --cc-primary-color: #{$color-dark};
    --cc-secondary-color: #{$color-dark};
    --cc-separator-border-color: transparent;

    --cc-modal-border-radius: 0;
    --cc-toggle-readonly-bg: #{$color-greenblue};
}

#cc-main .pm__section, 
#cc-main .pm__section--toggle {
    --cc-btn-border-radius: 0;
    --cc-toggle-off-bg: #{$color-subsub};
    --cc-cookie-category-block-bg: #{$color-bright};
    --cc-cookie-category-block-border: #{$color-subsub};
    --cc-cookie-category-block-hover-bg: #{$color-bright};
    --cc-cookie-category-block-hover-border: #{$color-subsub};
    --cc-cookie-category-expanded-block-bg: #{$color-bright};
    --cc-cookie-category-block-bg: #{$color-light};
    --cc-cookie-category-block-hover-bg: #{$color-mid};
    --cc-cookie-category-block-hover-border: #{$color-dark};
    --cc-cookie-category-expanded-block-hover-bg: #{$color-dark};
    --cc-toggle-on-bg: #{$color-greenblue-dark};
}

#cc-main .pm__badge {
    --cc-btn-secondary-bg: #{$color-yellow};
    --cc-secondary-color: #{$color-dark};
}

#cc-main .pm__section--toggle .pm__section-title {
    border-width: 1px;
}

#cc-main .pm__section--expandable .pm__section-arrow {
    --cc-toggle-readonly-bg: #{$color-dark};
}

#cc-main .pm__section--expandable .pm__section-arrow svg {
    stroke: #{$color-bright};
}

#cc-main .cc__link, 
#cc-main a {
    background: none;
    text-decoration: underline;
}

#cc-main .cc__link:hover, 
#cc-main a:hover {
    color: #{$color-yellow};
    text-decoration: none;
}

#cc-main .pm--bar:not(.pm--wide) .pm__btn+.pm__btn, #cc-main .pm--bar:not(.pm--wide) .pm__btn-group+.pm__btn-group {
    margin: 10px 0 0;
}

#cc-main .pm__btn+.pm__btn, #cc-main .pm__btn-group+.pm__btn-group {
    margin-left: 10px;
}

@media screen and (max-width: 640px) {
    #cc-main .pm__btn+.pm__btn, #cc-main .pm__btn-group+.pm__btn-group {
        margin: 10px 0 0 !important;
    }
}

#cc-main .pm__btn {
    border-width: 3px;
    font-size: .9em;
}

#cc-main .pm__title,
#cc-main .cm__title {
    color: $black;
    font-family: $title-font;
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: 1.35px;
    text-transform: uppercase;
}

@media(--medium-viewport) {
    #cc-main .pm__title,
    #cc-main .cm__title {
        font-size: 2.7rem;
    }
}

#cc-main .cm__footer {
    border-top-width: 3px;
}