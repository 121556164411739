$focus-color: $color-greenblue;
$select-color: $color-dark;
$select-color-hover: $color-light;
$select-background-color: $color-light;
$select-background-color-hover: $color-dark;
$select-border-color: $color-dark;
$select-border-width: 3px;
$select-border-color-hover: $color-dark;
$select-shadow-hover: none;
$select-shadow: 0px 4px 13px rgba(51, 47, 45, 0.2);

$checkbox-border-color: $color-dark;
$checkbox-border-color-hover: $color-dark;
$checkbox-border-error-color: $color-error;
$checkbox-border-width: 3px;
$checkbox-background-color: $color-light;
$checkbox-color-checked: $color-greenblue;
$checkbox-size: 25px;

input:focus {
    // https://moderncss.dev/custom-css-styles-for-form-inputs-and-textareas/
    border: 1px solid $focus-color;
    box-shadow: 0 0 0 3px rgba($focus-color ,0.3);
}

/* 
  Custom select from: https://www.filamentgroup.com/lab/select-css.html 
*/
.custom-select {
	display: block;
	font-size: 1.5rem;
	font-weight: 700;
	color: $select-color;
	line-height: 1.35;
	padding: 10px 10px;
    width: 100%;
    margin: 1em 0 2em;
	max-width: 100%;
	box-sizing: border-box;
    border: $select-border-width solid $select-border-color;
    border-radius: 0;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: $select-background-color;
    // background-image: $svg-arrow-down;
	background-repeat: no-repeat, repeat;
	background-position: right 1em top 40%;
    box-shadow: $select-shadow;
}

.custom-select:active,
.custom-select:focus {
    border-radius: 0;
    outline: none;
}

.custom-select option {
    background-color: transparent;
    color: $black;
    font-weight: normal;
    font-size: 14px;
    line-height: normal;
}

.select-css::-ms-expand {
	display: none;
}

.select-css:hover {
    background-color: $select-background-color-hover;
    border-color: $select-border-color-hover;
    box-shadow: $select-shadow-hover;
    color: $select-color-hover;
}
.select-css option {
	font-weight:normal;
}

.custom-select.select-language {
    background-image: $svg-icon-globe, $svg-arrow-down;
    background-position: left 10px center, right 10px center;
    background-repeat: no-repeat;
    background-size: 21px 21px, 16px 10px;
    padding-left: 48px;
    padding-right: 42px;
}

/* 
  Custom checkbox
  https://css-tricks.com/the-checkbox-hack/
*/

/* Base for label styling */
.form-check {
    margin: 0.5rem 0;

    label {
        padding-top: 0.1rem;
    }

    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
        position: absolute;
        left: -9999px;
    }

    [type="checkbox"]:not(:checked) + label,
    [type="checkbox"]:checked + label {
        position: relative;
        padding-left: 40px;
        cursor: pointer;
    }

    /* checkbox aspect */
    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before {
        content: '';
        position: absolute;
        left: 0; 
        top: 0.1rem;
        width: $checkbox-size; 
        height: $checkbox-size;
        background: $checkbox-background-color;
        border: $checkbox-border-width solid $checkbox-border-color;
        border-radius: 0;
        box-shadow: $shadow-ui-up;
    }

    // [type="checkbox"]:not(:checked) + label:before {
        // box-shadow: inset 0 5px 0 0 $light-blue3;
        // box-shadow: $shadow-ui-up;
    // }

    /* checked mark aspect */
    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after {
        content: '';
        background-image: $svg-checkmark;
        background-size: 16px;
        display: block;
        position: absolute;
        top: 5px; 
        left: 5px;
        font-size: 1.5rem;
        line-height: 1;
        transition: all .2s;
        width: 16px;
        height: 16px;
    }

    /* checked mark aspect changes */
    [type="checkbox"]:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
    }

    [type="checkbox"]:checked + label:after {
        opacity: 1;
        transform: scale(1);
    }

    /* disabled checkbox */
    [type="checkbox"]:disabled:not(:checked) + label:before,
    [type="checkbox"]:disabled:checked + label:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #ddd;
    }

    [type="checkbox"]:disabled:checked + label:after {
      color: $checkbox-color-checked;
    }

    [type="checkbox"]:disabled + label {
        color: #aaa;
    }

    /* accessibility */
    [type="checkbox"]:checked:focus + label:before,
    [type="checkbox"]:not(:checked):focus + label:before {
        outline: 2px solid $focus-color;
    }

    /* hover style just for information */
    // label:hover:before {
    //     border: $checkbox-border-width solid $checkbox-border-color-hover !important;
    // }
}

[type="checkbox"].error:not(:checked) + label:before,
[type="checkbox"].error:checked + label:before {
    border: $checkbox-border-width solid $checkbox-border-error-color;
}

[type="checkbox"].error + label {
    color: $color-error;
}

.form-item {
    margin-bottom: 1rem;
}

/* Lightswitch
 * https://codepen.io/oliviale/pen/xxboXzo?editors=0100
 */

.toggle input {
    height: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 0;
}

.toggle-wrapper * {
    box-sizing: border-box;
}

.toggle * {
	&:before, &:after {
		content: '';
		position: absolute;
	}
}

.toggle {
    align-items: center;
    position: relative;
    display: inline-flex;
}

label.toggle-item {
    background: #2e394d;
    border-radius: 50px;
    box-shadow: inset 0 0 0 3px $color-dark;
    cursor: pointer;
    display: block;
    height: 31px;
    margin: 10px 0;
    position: relative;
    transition: all .3s ease;
    transform-origin: 20% center;
    width: 61px;

    &::before {
        display: block;
        transition: all .2s ease;
        width: 21px;
        height: 21px;
        top: 5px;
        left: 5px;
        border-radius: 2em;
        border: 2px solid #88cf8f;
        transition: .3s ease;
    }
}

.normal {
    label {
        background: $color-grey-light;
        &::before {
            border: none;
            box-shadow: 0px 4px 13px rgba(51, 47, 45, 0.2);
            width: 21px;
            height: 21px;
            background-color: $color-sub;
            transform: rotate(-25deg);
        }
    }
}

.toggle input:checked + label {
    &:before {
        background: $color-greenblue-dark;
        left: 35px;
    }
}

.toggle .toggle-info {
    color: $color-sub;
    display: block;
    font-size: 1.8rem;
    font-weight: 700;
    padding-right: 15px;
    position: absolute;
    left: -200px;
    width: 200px;
    text-align: right;
    transition: opacity linear 1s;
}

.toggle .toggle-info-on {
    display: none;
    opacity: 0;
}

.toggle .toggle-info-off {
    display: block;
    opacity: 1;
}

.toggle input:checked ~ .toggle-info {
    color: $color-greenblue-dark;
    padding-left: 15px;
    padding-right: 0;
    left: auto;
    right: -200px;
    text-align: left;
}

.toggle input:checked ~ .toggle-info-on {
    display: block;
    opacity: 1;
}

.toggle input:checked ~ .toggle-info-off {
    display: none;
    opacity: 0;
}

@keyframes cup {
    0% { transform: none; }
    50% { transform: rotate(75deg) translate(10px,15px); }
    90% { transform: rotate(70deg) translate(10px,15px); }
    100% { transform: rotate(75deg) translate(10px,15px); }
}

@keyframes bounce-off {
    0% { transform: translateY(0); }
    10%,25% { transform: translate(-20px, -80px); }
    50% { transform: rotate(163deg); transform-origin: 100px -12px; }
    70% { transform: rotate(0) translate(-3px, -8px);  transform-origin: 100px -12px;  }
    75% { transform: translate(20px, -8px);}
    80% { transform:  translate(30px,0px) }
    85% { transform:  translate(40px, -3px) }
    87% { transform:  translate(46px, 0px) }
    90% { transform:  translate(52px, -1px) }
    95% { transform:  translate(60px, 0px) }
    100% { transform:  translate(64px,0px);}
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}