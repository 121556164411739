$product-card-width: 320px;
$product-card-body-height: 350px;
$product-card-header-height: 110px;
$product-card-side-padding: 160px;

/* Jobs Cards */
.jobs-card {
    width: 324px;
    flex-direction: row;
}

.jobs-card a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.jobs-card .card-header h3 {
    font-size: 2.4rem;
    font-weight: 400;
    margin: 10px;
}

.jobs-card .card-header h4 {
    font-family: $base-font;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 10px;
    text-transform: none;
}

.jobs-card .card-image img {
    display: block;
    position: absolute;
    left: -9px;
    bottom: 0;
    max-width: none;
}

.jobs-card .card-header,
.jobs-card .card-image {
    border: $border-width solid $color-dark;
    width: 100%;
}

.jobs-card .card-header {
    background-color: $color-bright;
    border-top: 0;
    overflow: hidden;
    padding: 12px;
    flex: 1;
}

.jobs-card .card-image {
    background-color: $color-yellow;
    overflow: visible;
    position: relative;
    height: 224px;
}

.jobs-list {
    overflow: hidden;
}

.jobs-list .inner {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    gap: 81px 71px;
    padding: 30px 0;
}

.jobs-list .jobs-card {
    position: relative;
    overflow: visible;
}

.jobs-list .jobs-card::before {
    content: "";
    display: block;
    position: absolute;
    border-bottom: 6px solid $color-dark;
    z-index: -1;
    left: -100vw;
    right: -100vw;
    top: 50%;
}

@media only screen and (min-width: $viewport-x-large) {
    .jobs-list {
        padding: 60px 0;
    }
}

/* Mixers Cards */

.mixers-card {
    width: 324px;
    flex-direction: row;
}

.mixers-card a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.mixers-card .card-type {
    background-color: $color-dark;
    color: $color-light;
    font-family: $title-font;
    font-size: 2.1rem;
    font-weight: 400;
    line-height: 1;
    padding: 15px 15px 4px 15px;
    text-transform: uppercase;
    overflow: visible;
    width: fit-content;
}

.mixers-card .card-header,
.mixers-card .card-image {
    border: $border-width solid $color-dark;
    width: 100%;
}

.mixers-card .card-header {
    background-color: var(--drink-primary-color);
    border-bottom: 0;
    overflow: hidden;
    padding: 20px;
    flex: 1;
}

.mixers-card .card-header h3 {
    font-size: 2.4rem;
    font-weight: 400;
    margin: 0;
}

.mixers-card .card-header h4 {
    font-family: $base-font;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 10px;
    text-transform: none;
}

.mixers-card .card-footer {
    background-color: $color-bright;
    border: $border-width solid $color-dark;
    border-top: 0;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.4;
    padding: 20px;
}

.mixers-card .card-image {
    background-color: $color-yellow;
}

.mixers-card .card-image img {
    display: block;
}

.mixers-list {
    overflow: hidden;
}

.mixers-list .inner {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    gap: 81px 71px;
    padding: 30px 0;
}

.mixers-list .mixers-card {
    position: relative;
    overflow: visible;
}

.mixers-list .mixers-card::before {
    content: "";
    display: block;
    position: absolute;
    border-bottom: 6px solid $color-dark;
    z-index: -1;
    left: -100vw;
    right: -100vw;
    top: 50%;
}

@media only screen and (min-width: $viewport-x-large) {
    .mixers-list {
        padding: 60px 0;
    }
}

/* Product Cards */

.product-card {
    margin-bottom: 42px;
    margin-top: 50px;
    overflow: visible;
    position: relative;
    filter: drop-shadow(0px 4px 13px rgba(51, 47, 45, 0.2));
    width: 100%;
    max-width: #{$product-card-width};
    // opacity: .2;
}

.product-card .card-body {
    background-color: $color-mid;
    border: $border-width solid $color-dark;
}

.product-card .card-body .col + .col {
    border-top: $border-width solid $color-dark;
}

.product-card .card-body .col-name {
    // padding: 14px 0;
    align-items: center;
    background-color: $color-purple;
    display: flex;
    justify-content: center;
    height: 110px;
}

/* Colours
Ipapotamus = E1A97B
Baron  = 158288
Battelaar = 89CBBF 
Springbok = EDD66B
Oerknal = D0B2D1

Framboos = A7D8CF
Pompelmoes-hop = 6CC6A3
Gember-Citroen = F3E18A
Cola = E7A495

Battelberg = 158288
Hopstijger = 0C7344
 */

.product-card.ipapotamus .card-body .col-name {
    background-color: #E1A97B;
}

.product-card.baron .card-body .col-name {
    background-color: #158288;
}

.product-card.battelaar .card-body .col-name {
    background-color: #89CBBF;
}

.product-card.springbok .card-body .col-name {
    background-color: #EDD66B;
}

.product-card.oerknal .card-body .col-name {
    background-color: #D0B2D1;
}

.product-card.framboos .card-body .col-name {
    background-color: #A7D8CF;
}

.product-card.pompelmoes-hop .card-body .col-name {
    background-color: #6CC6A3;
}

.product-card.gember-citroen .card-body .col-name {
    background-color: #F3E18A;
}

.product-card.cola .card-body .col-name {
    background-color: #E7A495;
}

.product-card.battelberg .card-body .col-name {
    background-color: #158288;
}

.product-card.hopstijger .card-body .col-name {
    background-color: #0C7344;
}

.product-card .card-body .col-name svg {
    width: 100%;
}

.product-card .card-body .col-packshot {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 30px 40px;
    height: $product-card-body-height;
}

.product-card.entry-type-spirit .card-body .col-packshot img,
.product-card.entry-type-lemonade .card-body .col-packshot img {
    position: relative;
    bottom: 22px;
    max-width: 150px;
}

/* Cards colours */
.product-card.c-orange .card-body {
    background-color: $color-orange;
}

.product-card.c-pink .card-body {
    background-color: $color-pink;
}

.product-card.c-purple .card-body {
    background-color: $color-purple;
}

.product-card.c-green .card-body {
    background-color: $color-green;
}

.product-card.c-greenblue .card-body {
    background-color: $color-greenblue;
}

.product-card.c-yellow .card-body {
    background-color: $color-yellow;
}

.product-card .card-header {
    background-color: $color-dark;
    color: $color-light;
    display: inline-block;
    font-family: $title-font;
    font-size: 2.1rem;
    font-weight: 400;
    line-height: 1.14;
    margin: 0;
    min-height: 50px;
    padding: 15px 15px 10px 15px;
    position: absolute;
    text-transform: uppercase;
    transform: translateY(-100%);
}

.product-list {
    position: relative;
    max-width: $container-width;
    margin: 0 auto;
}

.product-list-grid {
    border-left: 6px solid $color-dark;
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.product-list,
.product-list-grid {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 85px 25px;
}   

@media only screen and (min-width: $page-width) {
    /* Align cards */
    .product-list,
    .product-list-grid {
        align-items: flex-start;
        display: flex;
        flex-flow: row wrap;
        flex-direction: row;
        padding: 94px 0;
        max-width: 930px;
        margin: 0 auto;
    }

    .product-list-grid {
        border-left: 0;
        left: 0;
    }

    /* every 4th */
    .product-card:nth-child(4n) {
        margin-left: #{$product-card-side-padding};
        margin-right: calc(100% - (#{$product-card-width} * 2) - #{$product-card-side-padding});
    }

    /* every 5th start from 1 */
    .product-card:nth-child(4n+1) {
        margin-left: calc(100% - (#{$product-card-width} * 2) - #{$product-card-side-padding});
        margin-right: #{$product-card-side-padding};
    }

    /* Cards grid */
    .product-list-grid .product-card {
        border: $border-width solid red;
        height: $product-card-body-height + $product-card-header-height + ($border-width * 2);
        width: $product-card-width;
        position: relative;
    }

    .product-list-grid .product-card:nth-child(2n+2)::before {
        border: $border-width solid $border-color;
        content: '';
        display: block;
        height: 288px;
        position: absolute;
        width: 480px;
        top: 110px;
        left: -380px;
        z-index: -1;
    }

    .product-list-grid .product-card:nth-child(2n+2)::after {
        border: $border-width solid $border-color;
        content: '';
        display: block;
        height: 288px;
        position: absolute;
        width: 480px;
        top: 110px + 288px - $border-width;
        left: -380px;
        z-index: -1;
    }

    .product-list-grid .product-card:nth-child(4n)::before {
        left: -250px;
    }

    .product-list-grid .product-card:nth-child(4n)::after {
        left: -250px;
    }

    .product-list-grid .product-card:last-child::after {
        content: none;
    }

}

/* Card Beer */
.card-beer {
    background-color: var(--drink-base-color, transparent);
    border-bottom: $border-width solid var(--drink-border-color, $border-color);
    padding-top: 50px;
    padding-bottom: 80px;
    overflow: hidden;
}

.card-beer .inner {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}

.card-beer .card-body {
    border: $border-width solid var(--drink-border-color, $border-color);
    width: 100%;
    position: relative;
}

.card-beer .card-body h2 {
    font-size: 2.1rem;
}

.card-beer .card-body svg {
    display: block;
    width: 100%;
    height: auto;
}

.card-beer .card-body .col {
    padding: 0 25px;
}

.card-beer .card-body .col + .col {
    border-top: $border-width solid var(--drink-border-color, $border-color);
}

.card-beer .card-body .col > *:first-child {
    margin-top: 0;
}

.card-beer .card-body .col > *:last-child {
    margin-bottom: 0;
}

.card-beer .card-body .col-name {
    background-color: var(--drink-primary-color, $border-color);
    padding: 10px 0;
    position: relative;
}

.card-beer .card-body .col-name::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: -100%;
    right: 0;
    border-top: $border-width solid var(--drink-border-color, $border-color);
    z-index: -1;
}

.card-beer .card-body .col-packshot {
    background-color: $color-mid;
    display: flex;
    padding: 35px 25px;
}

.card-beer .card-body .packshot {
    margin: 0 auto;
    max-width: 100%;
}

.card-beer .card-body .col-description {
    background-color: var(--drink-secondary-color, $color-mid);
    color: var(--drink-text-color, $color-dark);
    padding: 30px 25px;
    position: relative;
}

.card-beer .card-body .col-description::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: calc((100vw - 100%) / 2 * -1);
    left: 0;
    border-top: $border-width solid var(--drink-border-color, $border-color);
    z-index: -1;
}

.card-beer .card-body .col .alcohol-vol {
    margin-bottom: -3px;
    position: relative;
    transform: translateY(50%);
}

.card-beer .card-header {
    background-color: var(--drink-border-color, $border-color);
    color: var(--drink-header-color, $color-light);
    font-family: $title-font;
    font-size: 2.1rem;
    font-weight: 400;
    line-height: 1;
    padding: 15px 15px 4px 15px;
    text-transform: uppercase;
    overflow: visible;
}

@media only screen and (min-width: $page-width) {
    .card-beer {
        position: relative;
    }

    .card-beer .card-body {
        border: 0;
        display: grid;
        grid-template-columns: 62% 38%;
        grid-template-rows: auto 1fr;
    }

    .card-beer .card-body .col {
        align-self: start;
    }

    .card-beer .card-body .col-name {
        border: $border-width solid var(--drink-border-color, $border-color);
        margin-bottom: 54px;
    }
    
    .card-beer .card-body .col-description {
        border: $border-width solid var(--drink-border-color, $border-color);
        font-size: 2.1rem;
        padding: 40px 50px;
    }
    
    .card-beer .card-body .col-description::before {
        display: none;
    }

    .card-beer .card-body .col-packshot {
        background-color: $color-mid;
        border: $border-width solid var(--drink-border-color, $border-color);
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        padding: 30px 25px;
        position: relative;
        margin-left: -6px;
    }

    .card-beer .card-body .col-packshot::before {
        content: "";
        display: block;
        position: absolute;
        top: 70%;
        right: calc((100vw - #{$container-width} + (#{$border-width} * 2)) / 2 * -1);
        left: 0;
        border-top: $border-width solid var(--drink-border-color, $border-color);
        z-index: -1;
    }
}
