.row .col {
    margin: 0 $col_margin;
    overflow: hidden;
}

.row-footer .col {
    overflow: hidden;
}

.row-footer .col + .col {
    border-top: 6px solid $color-dark;
}

.row-footer .sidebar {
    overflow: hidden;
}

@media only screen and (min-width: $page-width) {
    .row {
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;        
    }

    .row .col {
        margin: 0;
    }

    .row-footer {
        display: grid;
        grid-template-columns: 1fr 486px;
        grid-template-rows: auto;
        grid-template-areas: 
        "col1 sidebar"
        "col2 sidebar";
        padding-right: calc((100vw - #{$container-width}) / 2);
    }

    .row-footer .col {
        padding-left: calc((100vw - #{$container-width}) / 2);
    }

    .row-footer .col:first-child {
        grid-area: col1;
    }

    .row-footer .col:last-child {
        grid-area: col2;
    }

    .row-footer .col + .col {
        border-bottom: 0;
    }

    .row-footer .sidebar {
        border-left: 6px solid $color-dark;
        border-right: 6px solid $color-dark;
        grid-area: sidebar;
    }
}