.page-content .block:last-child {
    margin-bottom: 0;
}

/* block CTA */
.block-cta {
    text-align: center;
}

.block-cta .btn {
    position: relative;
    margin: 80px auto 0;
}

.block-cta.block-cta-workshop {
    align-items: center;
    background-color: $color-mauve;
    border: $border-width solid $border-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 30px $col-margin;
    max-width: 920px;
}

.block-cta.block-cta-workshop h2 {
    font-size: 3.3rem;
    line-height: 1.15;
    margin: 5px 0;
}

.block-cta.block-cta-workshop .btn {
    margin: 0;
}

.block-cta.block-cta-workshop .block-cta-body {
    padding: 20px 25px;
}

.block-cta.block-cta-workshop .block-cta-header {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

@media only screen and (min-width: 700px) {
    .block-cta.block-cta-workshop {
        display: grid;
        grid-template-columns: 213px 1fr;
        max-width: 920px;
    }

    .block-cta.block-cta-workshop .block-cta-header {
        display: block;
    }

    .block-cta.block-cta-workshop h2 {
        font-size: 4.3rem;
    }

    .block-cta.block-cta-workshop .block-cta-body {
        padding: 50px 25px;
    }
}

@media only screen and (min-width: 970px) {
    .block-cta.block-cta-workshop {
        margin: 30px auto;
    }
}

/* block UNTAPPD */
.block-untappd {
    margin-bottom: -30px;
    margin-top: -30px;
    position: relative;
    text-align: center;
}

.block-untappd .btn {
    margin: 0 auto;
}

// .block-cta {
//     border-top: $border-width solid $border-color;
//     display: flex;
//     justify-content: center;
//     margin: 50px 0;
//     padding: 0 $col_margin;
//     position: relative;
// }

// .block-cta .btn {
//     position: relative;
//     margin-top: -30px;
// }

// @media only screen and (min-width: $page-width) {
//     .block-cta {
//         justify-content: flex-start;
//         margin: 60px 0;
//         padding-left: calc((100vw - #{$container-width}) / 2);
//         padding-right: calc((100vw - #{$container-width}) / 2);
//     }
// }

/* block section */
.block-section {
    border-bottom: $border-width solid $border-color;
    margin-top: -22px;
    padding-bottom: 60px;
    padding-left: $col_margin;
    padding-right: $col_margin;
    position: relative;
}

.block-section-center {
    border-top: $border-width solid $border-color;
    margin-top: 70px;
    margin-bottom: 60px;
    // padding-left: $col_margin;
    // padding-right: $col_margin;
}

.block-section-center .inner {
    margin: 0;
}

.block-section:last-child {
    border-bottom: 0;
}

@media only screen and (min-width: $page-width) {
    .block-section  {
        padding-left: calc((100vw - #{$container-width}) / 2);
        padding-right: calc((100vw - #{$container-width}) / 2);
    }
}

.block-section picture {
    display: block;
}

.block-section .block-header {
    position: relative;
}

// .block-section .block-header h2 {
.block-header h2 {
    background-color: $color-dark;
    color: $color-light;
    font-size: 2.1rem;
    display: inline-block;
    margin: 0;
    padding: 15px 15px 10px 15px;
}

.block-title {
    text-align: center;
    padding-bottom: 60px;
    position: relative;
}

.block-title-inner {
    background-color: $color-dark;
    color: $color-light;
    font-size: 2.1rem;
    display: inline-block;
    margin: 0;
    padding: 14px 15px 10px 15px;
}

.block-title-light {
    background-color: $color-light;
    border: 6px solid $color-dark;
    color: $color-dark;
    display: inline-block;
    font-size: 1.8rem;
    margin: 0;
    padding: 14px 15px 10px 15px;
    text-transform: lowercase;
}

// .block-section .block-header h3 {
.block-header h3 {
    font-size: 4.2rem;
    margin: 17px 0 12px;
}

// .block-section .block-header h2,
// .block-section .block-header h3 {
.block-header h2,
.block-header h3 {
    word-wrap: break-word;
    overflow-wrap: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

.block-section-center .block-header {
    position: relative;
    text-align: center;
    margin-top: -28px;
    margin-bottom: 25px;
}

.block-section-center .block-header h2 {
    background-color: $color-dark;
    color: $color-light;
    font-size: 2.1rem;
    display: inline-block;
    margin: 0;
    padding: 15px 15px 10px 15px;
}

@media only screen and (min-width: $page-width) {
    .block-header h2 {
        font-size: 2.4rem;
    }

    .block-section-center {
        padding-left: 0;
        padding-right: 0;
    }
}

.block-section img {
    border: $border-width solid $border-color;
}

.block-section .block-drawing {
    align-items: flex-start;
    margin: 25px 0;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: -1;
}

.block-drawing img {
    border: 0;
    display: block;
}

.block-section .block-content {
    background-color: $color-bright;
    border: $border-width solid $border-color;
    padding: 30px 25px;
    position: relative;
}

.block-section .block-content > *:first-child {
    margin-top: 0;
}

.block-section .block-content > *:last-child {
    margin-bottom: 0;
}

.block-section .block-content + .block-content {
    margin-top: 25px;
}

.block-section .block-content + .block-content::before {
    content: "";
    display: block;
    border-left: $border-width solid $border-color;
    top: (25px + $border-width) * -1;
    bottom: 50%;
    left: 50%;
    margin-left: -3px;
    position: absolute;
    z-index: -1;
}

.block-section .block-header *:last-child {
    margin-bottom: 11px;
}

.block-section .block-header h2:last-child {
    margin-bottom: 25px;
}

.block-section footer {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    display: flex;
    position: relative;
    z-index: 1;
}

.block-section footer .btn {
    margin: 25px 0 0;
}

.block-section footer::before {
    content: "";
    display: block;
    border-left: $border-width solid $border-color;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
    position: absolute;
    z-index: -1;
}

@media only screen and (min-width: $page-width) {
    .block-drawing {
        margin: 50px 0;
    }

    img.drawing {
        max-width: none;
    }

    .block-section .block-content {
        padding: 30px 50px;
    }

    .block-section .block-header h2 {
        font-size: 2.4rem;
    }

    .block-section .block-header h3 {
        font-size: 5.4rem;
        margin-top: 25px;
    }

    .block-section .block-header h2:last-child {
        margin-bottom: 80px;
    }
}

$section-margin: 55px;
$section-page-width: $viewport-x-large;

/* Block Section: Bottom Left  */
.section-bottom-left .block-content,
.section-bottom-right .block-content {
    // border-top-width: 0;
    margin-top: -#{$border-width};
}

@media only screen and (min-width: $section-page-width) {
    .section-bottom-left,
    .section-bottom-right {
        display: flex;
        flex-flow: row wrap;
    }

    .section-bottom-left .col {
        width: calc(50% + 25px);
    }

    .section-bottom-left .col:nth-child(1) {
        margin-right: -25px;
    }
    
    .section-bottom-left .col:nth-child(2) {
        margin-left: -25px;
    }

    .section-bottom-left .col:nth-child(2) .block-drawing {
        position: relative;
        right: -#{$col_margin * 2};
        justify-content: flex-end;
    }

    .section-bottom-left header {
        padding-left: $section-margin;
        padding-right: $section-margin + 50px;
    }

    .section-bottom-left .block-content {
        // border-top-width: $border-width;
        margin-top: 0;
    }

    /* Block Section: Bottom Right  */
    .section-bottom-right .col {
        width: calc(50% + 25px);
    }

    .section-bottom-right .col:nth-child(1) {
        order: 2;
        margin-left: -25px;
    }
    
    .section-bottom-right .col:nth-child(2) {
        order: 1;
        margin-right: -25px;
    }

    .section-bottom-right .col:nth-child(2) .block-drawing {
        position: relative;
        left: -#{$col_margin * 2};
        justify-content: flex-start;
    }

    .section-bottom-right header {
        padding-left: $section-margin + 50px;
        padding-right: $section-margin;
    }

    .section-bottom-right .block-content {
        // border-top-width: $border-width;
        margin-top: 0;
    }

    .section-bottom-right footer {
        grid-column: 1;
    }
}

@media only screen and (min-width: $page-width) {
    .section-bottom-left .col:nth-child(2) .block-drawing {
        // right: calc((100vw - #{$container-width}) / 2 * -1);
        right: -25px;
        justify-content: flex-start;
    }

    .section-bottom-right .col:nth-child(2) .block-drawing {
        // left: calc((100vw - #{$container-width}) / 2 * -1);
        left: -25px;
    }
}

/* Block Section: Top Left  */
.section-top-left header,
.section-top-right header {
    margin-top: -22px;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 1;
}

.section-top-left .col:nth-child(1) .block-footer {
    display: none;
}

.section-top-left .col:nth-child(1) .block-drawing {
    display: none;
}

.section-top-right .col:nth-child(1) .block-footer {
    display: none;
}

.section-top-right .col:nth-child(1) .block-drawing {
    display: none;
}

/* Block Section: Top Left  */
@media only screen and (min-width: $section-page-width) {
    .section-top-left,
    .section-top-right {
        display: flex;
        flex-flow: row wrap;
    }

    .section-top-left .col {
        width: calc(50% + 25px);
    }

    .section-top-left .col:nth-child(1) {
        margin-right: -25px;
    }
    
    .section-top-left .col:nth-child(2) {
        margin-left: -25px;
    }

    .section-top-left header {
        padding-left: $section-margin + 50px;
        padding-right: $section-margin;
        margin-top: 0;
    }

    .section-top-left .block-content {
        border-top-width: $border-width;
    }

    // .section-top-left .col:nth-child(1) .block-footer {
    //     display: flex;
    // }

    // .section-top-left .col:nth-child(2) .block-footer {
    //     display: none;
    // }

    .section-top-left .col:nth-child(1) .block-drawing {
        display: block;
        position: relative;
        right: -#{$col_margin * 2};
        justify-content: flex-end;
    }

    .section-top-left .col:nth-child(2) .block-drawing {
        display: none;
    }
    
    /* Block Section: Bottom Right  */
    .section-top-right .col {
        width: calc(50% + 25px);
    }

    .section-top-right .col:nth-child(1) {
        order: 2;
        margin-left: -25px;
    }
    
    .section-top-right .col:nth-child(2) {
        order: 1;
        margin-right: -25px;
    }

    .section-top-right .col:nth-child(3) {
        order: 3;
    }

    .section-top-right header {
        padding-left: $section-margin;
        padding-right: $section-margin + 50px;
        margin-top: 0;
    }

    .section-top-right .block-content {
        border-top-width: $border-width;
    }

    // .section-top-right .col:nth-child(1) .block-footer {
    //     display: flex;
    // }

    // .section-top-right .col:nth-child(2) .block-footer {
    //     display: none;
    // }

    .section-top-right .col:nth-child(2) .block-drawing {
        display: none;
    }

    .section-top-right .col:nth-child(1) .block-drawing {
        display: block;
        position: relative;
        right: -#{$col_margin * 2};
        justify-content: flex-start;
    }
}

@media only screen and (min-width: $page-width) {
    .section-top-left .col:nth-child(1) .block-drawing {
        // right: calc((100vw - #{$container-width}) / 2 * -1);
        right: -25px;
    }

    .section-top-right .col:nth-child(1) .block-drawing {
        // right: calc((100vw - #{$container-width}) / 2 * -1);
        right: -25px;
    }
}

/* Block Section: Center */
.section-center {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
}

.section-center .block-header {
    text-align: center;
}

.section-center .block-content {
    margin-left: $col_margin;
    margin-right: $col_margin;
    margin-top: -35px;
    z-index: 1;
}

@media only screen and (min-width: $section-page-width) {
    .section-center {
        margin-left: 0;
        margin-right: 0;
        padding-left: calc((100% - #{$container-width}) / 2);
        padding-right: calc((100% - #{$container-width}) / 2);
    }

    .section-center .block-content {
        margin-top: -45px;
    }
}

/* Info Blocks */
.block-info-blocks {
    margin-bottom: 50px;
    margin-top: 50px;
}

.info-block {
    border: $border-width solid $border-color;
    font-size: 1.5rem;
    line-height: 1.4;
    padding: $col_margin;
}

.info-block + .info-block {
    margin-top: -#{$border-width};
}

.info-block h3 {
    color: $color-orange;
    font-size: 2.1rem;
    line-height: 1.142857143;
    margin-bottom: .5rem;
}

.info-block p {
    margin-top: 0;
}

.info-block > *:first-child {
    margin-top: 0;
}

.info-block > *:last-child {
    margin-bottom: 0;
}

@media only screen and (min-width: $section-page-width) {
    .block-info-blocks {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
        column-gap: 7%;
        row-gap: 32px;
    }

    .info-block {
        background-color: $color-light;
        border: $border-width solid $border-color;
        padding: $col_margin;
        margin: 0;
    }

    .info-block + .info-block {
        margin-top: 0;
    }

    .info-block::before {
        border-top: $border-width solid $border-color;
        content: "";
        display: block;
        left: 0;
        margin-top: -10px;
        position: absolute;
        right: 0;
        z-index: -1;
    }
}

.info-block dl {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto;
    column-gap: 30px;
    row-gap: 18px;
}

.info-block dt,
.info-block dd {
    margin: 0;
}

.info-block dt {
    font-family: $title-font;
    font-size: 2.1rem;
    font-weight: 400;
    margin-top: -0.2rem;
}

/* block video */
.block-video {
    border-bottom: $border-width solid $border-color;
}

@media only screen and (min-width: $page-width) {
    .block-video {
        background-color: $color-yellow;
        border-bottom: $border-width solid $border-color;
        text-align: center;
    }

    .block-video video {
        border-left: $border-width solid $border-color;
        border-right: $border-width solid $border-color;
        margin: 0 auto;
        max-width: 1190px;
    }
}

.theme-greenblue .block-video {
    background-color: $color-greenblue;
}

.theme-yellow .block-video {
    background-color: $color-yellow;
}

.theme-orange .block-video {
    background-color: $color-orange;
}

.theme-light .block-video {
    background-color: $color-light;
}

.theme-purple .block-video {
    background-color: $color-purple;
}

.theme-green .block-video {
    background-color: $color-green;
}

/* block fiche */
.block-fiches {
    background-color: var(--drink-base-color, $color-yellow);
    border-bottom: $border-width solid var(--drink-border-color, $border-color);
    display: flex;
    flex-direction: column;
    overflow: visible;
    position: relative;
}

.block-fiche {
    align-items: flex-start;
    border: $border-width solid var(--drink-border-color, $border-color);
    color: var(--drink-border-color, $color-dark);
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    font-weight: 600;
    margin: -6px 0;
    text-transform: uppercase;
}

.block-fiche + .block-fiche {
    margin-top: 0;
}

.fiche-row .block {
    background-color: $color-light;
    border: 3px solid var(--drink-border-color, $border-color);
    color: $color-dark;
    display: inline-block;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.166666667;
    text-transform: uppercase;
    padding: 14px 10px 10px 10px;
}

.block-group {
    display: flex;
    position: relative;
    z-index: 1;
}

.block-group .block {
    display: block;
    // font-size: 1.5rem;
}

.block-group .block + .block {
    margin-left: 10px;
    position: relative;
}

.block-group .block + .block::before {
    border-top: 4px solid var(--drink-border-color, $border-color);
    content: "";
    display: block;
    position: absolute;
    left: -23px;
    margin-top: -2px;
    right: auto;
    width: 20px;
    top: 50%;
    z-index: -1;
}

.block-fiche h3 {
    font-family: $title-font;
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0.2rem 0;
}

.block-fiche h3 + p {
    margin: 0;
}

.block-fiche .fiche-header {
    background-color: var(--drink-secondary-color, $color-light);
    border: $border-width solid var(--drink-border-color, $border-color);
    color: var(--drink-text-color, $color-dark);
    font-family: $title-font;
    font-weight: 400;
    font-size: 2.1rem;
    line-height: 1.142857143;
    margin-bottom: 15px;
    margin-top: -28px;
    margin-left: -6px;
    padding: 10px 9px 4px 10px;
}

.block-fiche .fiche-row {
    margin: 15px 0;
    padding: 0 12px;
}

.block-fiche .fiche-row:last-child {
    margin-bottom: 30px;
}

@media only screen and (min-width: $page-width) {
    .block-fiche {
        border-bottom-width: 0;
        border-top-width: 0;
        margin: 0;
        padding-bottom: 65px;
    }

    .block-fiches {
        padding-bottom: 0;
    }

    .block-fiches .cols {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 160px;
        width: 100%;
    }
}

.block-fiche:nth-child(2) {
    padding: 58px 0;
}

.block-fiche .fiche-category {
    align-items: center;
    display: flex;
    margin: 18px 0;
    padding-left: 0;
    position: relative;
}

.block-fiche .fiche-category::before {
    background-color: var(--drink-border-color, $color-dark);
    content: "";
    display: block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-left: -13px;
    margin-right: 15px;
}   

.block-fiche .fiche-table {
    border: 0;
    border-collapse: collapse;
    width: calc(100% - 28px);
    margin: 14px 14px 34px;
}

.block-fiche .fiche-table td {
    border-bottom: 3px solid $color-dark;
    padding: 8px 0;
}

/* Block Fiche Bottom */
.block-fiche-bottom {
    background-color: var(--drink-secondary-color, $color-green);
    border-bottom: $border-width solid $border-color;
    padding: 100px 0;
}

.block-fiche-bottom .col-illustration {
    border-color: var(--drink-border-color, $border-color) transparent;
    border-style: solid;
    border-width: $border-width 0 $border-width;
}

.block-fiche-bottom .col-illustration img {
    display: block;
    max-width: none;
    width: 100%;
}

.block-fiche-bottom .col-name {
    display: none;
}

.block-fiche-bottom .block-inner {
    margin: 0;
}

@media only screen and (min-width: $viewport-medium) {
    .block-fiche-bottom {
        padding: 0;
    }

    .block-fiche-bottom .col-illustration {
        border-color: transparent var(--drink-border-color, $border-color);
        border-width: 0 $border-width 0 $border-width;
        width: 445px;
    }

    .block-fiche-bottom .col-name {
        background-color: var(--drink-primary-color, $color-greenblue);
        border: $border-width solid var(--drink-border-color, $border-color);
        display: block;
        margin-top: 100px;
        margin-left: 25px;
    }

    .block-fiche-bottom .block-inner {
        align-items: flex-start;
        display: flex;
        max-width: $container-width;
        margin: 0 $col_margin;
    }
}

@media only screen and (min-width: $page-width) {
    .block-fiche-bottom .block-inner {
        margin: 0 auto;
    }
}

/* Block Intro */
.block-intro {
    font-size: 1.8rem;
    margin: 0 $col_margin 50px;
    text-align: center;
}

@media only screen and (min-width: $page-width) {
    .block-intro {
        font-size: 2.1rem;
        max-width: $container-narrow-width;
        margin: 0 auto 70px;
    }
}

/* Block Text */
.block-text {
    margin: 0 $col_margin;
}

@media only screen and (min-width: $page-width) {
    .block-text {
        max-width: $container-mid-width;
        margin: 0 auto;
    }
}

/* Block Title */
.block-title {
    margin: 0 $col_margin;
    text-align: center;
}

.block-title h1 {
    margin: 45px 0 0;
}

@media only screen and (min-width: $page-width) {
    .block-title {
        max-width: $container-width;
        margin: 0 auto;
    }

    .block-title h1 {
        margin: 70px 0 0;
    }
}

/* Flavor wheel */
.block-flavour-wheel {
    background-color: $color-light;
    border-radius: 50%;
    display: block;
    height: 330px;
    margin: 0 auto;
    width: 330px;
    overflow: hidden;
    position: relative;
    margin-top: -165px;
    margin-bottom: 50px;
}

.block-flavour-wheel svg {
    display: block;
    position: relative;
    z-index: 2;
}

.block-flavour-wheel #flavour-wheel {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 54px;
    z-index: 1;
}

.block-image {
    margin-bottom: 50px;
    margin-top: 50px;
}

.drink .block-image {
    margin-top: 20px;
}

@media only screen and (min-width: $page-width) {
    .drink .block-image {
        margin-bottom: 60px;
        margin-top: -30px;
    }
}