@font-face {
    font-family: 'Shentox-SemiBold';
    src: 
        url('./../assets/fonts/Shentox-SemiBold/font.woff2') format('woff2'), 
        url('./../assets/fonts/Shentox-SemiBold/font.woff') format('woff');
}

body {
    background-color: $color-light;
    color: $color-dark;
    font-family: $base-font;
    font-size: 1.8rem;
    line-height: 1.833333333;
    margin: 0;
    position: relative;
}

body::before {
    background: transparent url('./../assets/img/bg-texture.png') 0 0 repeat;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: z-index(texture);
    pointer-events: none;
}

p.intro {
    font-size: 2.1rem;
    font-weight: 600;
    line-height: 1.6;
}

a {
    color: inherit;
    font-weight: 700;
}

address {
    font-style: normal;
}

/* H1 Shentox 600 normal 112px */
h1 {
    font-family: $title-font;
    font-weight: 400;
    font-size: 5.2rem;
    line-height: 1;

    -ms-word-break: break-all;
    word-break: break-all;

    /* Non standard for WebKit */
    word-break: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
        hyphens: auto;

    text-transform: uppercase;
}

h2 {
    font-family: $title-font;
    font-weight: 400;
    font-size: 2.1rem;
    line-height: 1.2;
    text-transform: uppercase;
}

.block-text h2 {
    margin: 3rem 0;
}

.block-text h1 + h2 {
    margin-top: 0;
}

h3 {
    font-family: $title-font;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 1.2;
    text-transform: uppercase;
}

.block-text h3 {
    margin: 3rem 0;
}

h4 {
    font-family: $title-font;
    font-size: 2.1rem;
    font-weight: 400;
    line-height: 1.2;
    text-transform: uppercase;
    margin: 1rem 0 0;
}

@media only screen and (min-width: $page-width) {
    h1 {
        font-size: 11.2rem;
    }

    h2 {
        font-size: 2.4rem;
    }

    h3 {
        font-size: 2.1rem;
    }

    .block-text h2 {
        margin: 5rem 0;
    }

    .block-text h3 {
        margin: 5rem 0;
    }
    
    h4 {
        font-size: 2.8rem;
    }    
}

.block-content ol li::marker {
    content: counter(list-item)" ";
    font-weight: 700;
}

.block-content ol li {
    padding: 1.5em  0 1.5em 15px;
    position: relative;
}

.block-content ol li::after {
    border-bottom: 3px dotted $color-dark;
    content: '';
    display: block;
    left: -1em;
    right: 0;
    bottom: 0;
    position: absolute;
}

.section-title {
    background-color: $color-dark;
    color: $color-light;
    display: inline-block;
    font-family: $title-font;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 1;
    padding: 15px;
    text-transform: uppercase;
}

.large {
    font-size: 2.1rem;
    font-weight: 500;
}

img,
video {
    max-width: 100%;
    height: auto;
}

main {
    position: relative;
}

.opened + main {
    display: none;
}

@media only screen and (min-width: $page-width) {
    .opened + main {
        display: block;
    }
}

.inner {
    margin: 0 $col_margin;
}

@media only screen and (min-width: $page-width) {
    .inner {
        max-width: $container-width;
        margin: 0 auto;
    }
}

picture {
    display: block;
}

.age-form {
    margin: 100px 0;
}

/* Cookies Banner */
#age-verification {
    background-color: $color-light;
    padding-top: 78px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 998;
    display: none;
    overflow-y: auto;
}

.page-content {
    overflow: visible;
    padding-bottom: 80px;
    position: relative;
    z-index: 1;
}

/* Front Page */
.home .page-header {
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 300px auto;
    min-height: 255px;
}

@media only screen and (min-width: $page-width) {
    .home .page-header {
        background-size: 474px auto;
        min-height: 290px;
    }
}

/* Overlay (error pages) */
.overlay {
    border-top: $border-width solid $border-color;
    margin-top: 50vh;
}

.overlay h2 {
    font-size: 2.1rem;
    text-align: center;
}

.overlay .site-name {
    margin-top: -37px;
    margin-bottom: 37px;
    min-height: auto;
}

.overlay .site-name svg {
    bottom: auto;
}

.overlay .page-content {
    padding: 60px 0;
}

/* Drink Page */
// .drink .page-content {
//     padding-bottom: 0;
// }

@media only screen and (min-width: $page-width) {
    .drink .page-content .description {
        margin-right: 40%;
    }
}

.alcohol-vol {
    background-color: $color-dark;
    color: $color-light;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: $title-font;
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 1.1;
    padding: 5px;
    text-align: center;
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    width: 124px;
    min-height: 140px;
}

.alcohol-vol strong {
    display: block;
    font-size: 4.1rem;
}

/* Tables */
.block-content table {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;
}

.block-content td {
    padding: 0 0 15px;
    vertical-align: top;
}

.block-content td + td {
    padding-left: 10px;
}

.block-content td:not(:first-child) {
    text-transform: uppercase;
}

.block-text table {
    width: 100%;
    border-collapse: collapse;
    margin: 5rem 0;
}

table.time-table tr td:first-child {
    white-space: nowrap;
}

.block-text th {
    font-weight: 600;
    text-align: left;
}

.block-text td {
    border-bottom: 3px solid $color-dark;
    padding: 20px 10px 30px;
    vertical-align: top;
}

.block-text th {
    padding: 20px 10px 10px;
    vertical-align: top;
    border-bottom: 6px solid $color-dark;
}

/* Brewery */
.drinks-page .page-content {
    padding-bottom: 0;
}

@media only screen and (min-width: $page-width) {
    .drinks-page .page-content {
        padding-bottom: 80px;
    }
}

/* error-404 */
.error-404 .page-content {
    padding-top: 0;
    text-align: center;
}

.error-404 h2 {
    font-size: 3rem;
    margin: 1.5rem 0;
}

@media only screen and (min-width: $page-width) {
    .error-404 h2 {
        font-size: 5.4rem;
        margin: 3rem 0;
    }
}

/* Map */
#map-parking {
    height: 65vh;
    margin: 0 0 30px;
}

@media only screen and (min-width: $page-width) {
    #map-parking {
        height: 65vh;
        margin: 0 0 60px;
    }
}

.info-window h3 + p {
    margin-top: -1em;
}

/* Mixers */
.mixer .page-header {
    background-color: var(--drink-primary-color);
}