$step-color: $color-mid;
$step-active-color: $color-dark;

.multi-step-progress-bar {
    align-items: center;
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin: 20px 0;
    padding: 0;
}

.multi-step-progress-bar li {
    margin: 0 15px;
}

.multi-step-progress-bar li span {
    margin: 0;
    padding: 0;

    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.multi-step-progress-bar li::before {
    background-color: $step-color;
    content: "";
    border-radius: 50%;
    display: block;
    height: 12px;
    width: 12px;
}

.multi-step-progress-bar li.active::before {
    background-color: $step-active-color;
}