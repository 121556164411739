/* Cookies Banner */
.modal {
    background-color: rgba($color-dark, 0.8);
    display: none;
    line-height: 1.4;
    overflow-y: scroll;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: z-index(modal);
}

.modal h2 {
    font-size: 3rem;
    text-transform: uppercase;
    margin: 1rem 0 -0.5rem;
}

.modal h2 + p {
    margin-top: 1rem;
}

.modal h3 {
    font-size: 2.1rem;
    margin: 30px 0 0;
}

.modal h4 {
    font-size: 1.8rem;
    margin: 0;
}

.modal p {
    margin: 1.2em 0;
}

.modal picture {
    margin: 30px;
}

.modal strong {
    color: $color-greenblue-dark;
}

.modal-body,
.modal-body-expand,
.modal-header,
.modal-footer {
    &:last-child {
        border-bottom: 0;
    }

    & > *:first-child {
        margin-top: 0;
    }

    & > *:last-child {
        margin-bottom: 0;
    }
}

.modal-box {
    align-items: flex-start;
    background-color: $color-bright;
    border: $border-width solid $border-color;
    border-top: $border-width solid $border-color;
    display: flex;
    justify-content: flex-end;
    flex-flow: column-reverse;
    min-height: 100vh;
}

.modal-content {
    width: 100%;
    max-width: $modal-width;
    margin: 0 auto;
}

.modal-dialog {
    width: 100%;
}

// @media only screen and (min-width: $modal-width) {
@media only screen and (min-width: $nav-page-width) {
    .modal {
        align-items: center;
        justify-content: center;
        flex-flow: column;
        overflow-y: scroll;
    }

    .modal-box {
        min-height: auto;
    }

    .modal-dialog {
        margin: 30px 0;
        width: $modal-width;
        max-width: none;
    }
}

.modal-body,
.modal-body-expand {
    border-bottom: $border-width solid $border-color;
    font-size: 1.5rem;
    padding: 20px 10px;
    text-align: center;
    width: 100%;
}

.modal-body-expand {
    background-color: $color-light;
}

.modal-footer {
    align-items: center;
    background-color: $color-mid;
    border-bottom: $border-width solid $border-color;
    display: flex;
    justify-content: center;
    font-size: 1.3rem;
    gap: 15px;
    padding: 12px;
    width: 100%;
}

// @media only screen and (min-width: $modal-width) {
@media only screen and (min-width: $nav-page-width) {
    .modal-body-expand {
        padding: 20px 40px;
    }

    .modal-footer {
        justify-content: space-between;
        font-size: 1.5rem;
    }
}

.modal .logo-desktop {
    margin: 25px auto 15px;
}

.modal .logo-desktop .b {
    fill: $color-bright;
}

.modal .dropdown {
    margin: 0;
}

.modal .dropdown button {
    margin: 0;
}

.modal .btn-group .btn {
    margin-top: 0;
    margin-bottom: 2rem;
}

.modal p + .toggle-wrapper {
    margin-top: -1rem;
}

.modal-open {
    overflow: hidden;
}

.modal-open .modal {
    display: flex;
}


/* Age Modal v2 */

// #age-modal {
//     border: none;
//     color: var(--clr-bright);
//     position: absolute;
//     inset: 0;
//     z-index: var(--zindex-modal);
//     width: 100vw;
//     height: 100vh;
//     max-width: 100%;
//     max-height: 100%;
// }

// .open-age-modal #container {
//     display: none;
// }

// #age-modal .logo {
//     margin-bottom: 30px;
//     max-width: 40%;
// }

// @media(--small-m-viewport) {
//     #age-modal .logo {
//         margin-bottom: 50px;
//         max-width: 100%;
//     }
// }