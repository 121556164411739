#main-footer {
    background-color: $color-mid;
    font-size: 1.5rem;
    position: relative;
    z-index: z-index(footer);
}

#main-footer .footer-details {
    background-color: $color-bright;
}

#main-footer .footer-location,
#main-footer .footer-contact {
    line-height: 1.6;
}

#main-footer > * {
    border-bottom: 6px solid $color-dark;
}

#main-footer h2 {
    font-size: 2.1rem;
}

#main-footer a {
    font-weight: 400;
}

#main-footer a:hover {
    text-decoration: none;
} 

#main-footer .logo-footer {
    border: none;
    justify-self: center;
    margin-left: -69px;
    margin-top: -16px;
    left: 50%;
    position: absolute;
}

#main-footer .logo-footer .b {
    fill: $color-light;
}

@media only screen and (min-width: $page-width) {
    #main-footer .logo-footer {
        left: calc(((100vw - #{$container-width}) / 2) - 138px);
        margin-left: 0;
        margin-top: 0;
        top: 30px;
        transform: rotate(-90deg);
        transform-origin: top right;
    }

    #main-footer .logo-footer .b {
        fill: $color-mid;
    }
}

/* Row footer */
.row-footer {
    border-top: 6px solid $color-dark;
}

/* Footer bottom */
.footer-bottom {
    border-bottom: 0 !important;
    font-size: 1.3rem;
}

.footer-bottom,
.footer-bottom a {
    color: $color-dark;
}

.footer-bottom strong {
    color: $color-dark;
    font-size: 1.6rem;
    font-weight: 600;
}

.footer-bottom .row {
    align-items: center;
    display: grid;
    justify-items: center;
    grid-auto-columns: auto;
    padding: 20px 0;
}

.footer-bottom .row .col {
    padding: 5px 0;
    position: relative;
    text-align: center;
    line-height: 1.65;
}

.footer-bottom .row .col + .col::before {
    border-top: 3px solid $color-dark;
    content: "";
    display: block;
    width: 20px;
    margin: 10px auto;
}

// .inner {
//     margin: 0 $col_margin;
// }

// @media only screen and (min-width: $page-width) {
//     .inner {
//         max-width: $container-width;
//         margin: 0 auto;
//     }
// }

@media only screen and (min-width: $page-width) {
    .footer-bottom .inner {
        max-width: none;
    }

    .footer-bottom .row {
        justify-content: center;
        padding: 35px 0;
    }

    .footer-bottom .row .col {
        padding: 0 20px;
        text-align: left;
    }

    .footer-bottom .row .col + .col::before {
        border-left: 3px solid $color-dark;
        border-top: 0;
        height: 20px;
        left: 0;
        margin: 0;
        margin-top: -10px;
        position: absolute;
        width: 1px;
        top: 50%;
    }
}

.footer-details {
    font-size: 1.5rem;
    padding: 18px 50px 40px;
    text-align: center;
}

.footer-details h2 {
    margin-top: 40px;
    margin-bottom: 1rem;
}

.footer-details h2 + p {
    margin: 0;
}

.footer-details table {
    margin: 0 auto;
}

.footer-details table td:nth-child(odd) {
    text-align: right;
}

.footer-details table td:nth-child(even) {
    text-align: left;
}

.footer-details table td + td {
    padding-left: 16px;
}

@media only screen and (min-width: $page-width) {
    .footer-details {
        display: grid;
        gap: 0 35px;
        grid-template-columns: 1fr 1fr;
        padding-top: 0;
        text-align: left;
    }

    .footer-details .footer-hours {
        grid-column: 1/3;
    }

    .footer-details table {
        margin: 0;
    }

    .footer-details table td {
        text-align: left !important;
    }
}

#main-footer .footer-external-links h2 {
    font-size: 1.5rem;
    font-family: $base-font;
    font-weight: 700;
    line-height: 1.666666667;
    margin: 2rem 0 2rem;
    text-transform: none;
}

.footer-external-links {
    text-align: center;
}

@media only screen and (min-width: $page-width) {
    .footer-external-links {
        text-align: left;
    }    
}

.footer-links {
    display: none;
}

@media only screen and (min-width: $page-width) {
    .footer-links {
        display: block;
    }
    
    .footer-links .footer-menu {
        padding-left: 110px;
    }
}

.footer-bottom .col {
    overflow: visible;
}

.footer-bottom select {
    margin: 0;
}

@media only screen and (min-width: $page-width) {
    .footer-bottom .dropdown {
        margin: -10px 0;
    }
}