.dropdown {
    margin: 1rem 0;
}

.dropdown-item {
    margin: 0;
}

/* Dropdown Language  */
.dropdown.dropdown-language button {
    background-color: $color-bright;
    background-image: $svg-icon-globe-small, $svg-arrow-down-small;
    background-position: left 8px center, right 8px center;
    background-repeat: no-repeat;
    background-size: 12px 12px, 10px 7px;
    border: 3px solid $color-dark;
    font-family: $base-font;
    font-size: 1.5rem;
	font-weight: 700;
    padding-left: 28px;
    padding-right: 26px;
    height: 44px;
}

.dropdown.dropdown-language button:active,
.dropdown.dropdown-language button:hover {
    background-color: $color-bright;
    border: 3px solid $color-dark;
    color: $color-dark;
}

.dropdown.dropdown-language button:after {
    content: none;
}

.dropdown-language .dropdown-item {
    font-size: 1.5rem;
    color: $color-dark;
}

.dropdown-language .dropdown-menu {
    background-color: $color-bright;
    border: 2px solid rgba($color-dark, 0.5);
    border-radius: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
    background-color: rgba($color-dark, 0.1);
}

/* Dropdown Language Compact */
.dropdown.dropdown-language-compact {
    margin: 2px 0;
}

.dropdown.dropdown-language-compact button {
    background-color: transparent;
    background-image: $svg-icon-globe-small, $svg-arrow-down-small;
    background-position: left 5px center, right 5px center;
    background-repeat: no-repeat;
    background-size: 12px 12px, 10px 7px;
    border: 3px solid transparent;
    box-shadow: none;
    font-family: $base-font;
    font-size: 1.5rem;
	font-weight: 400;
    padding-left: 26px;
    padding-right: 20px;
    height: 44px;
    margin: 0;
}

.dropdown.dropdown-language-compact button:active,
.dropdown.dropdown-language-compact button:hover {
    background-color: transparent;
    // border: 3px solid $color-dark;
    color: $color-dark;
}

.dropdown.dropdown-language-compact button:after {
    content: none;
}

.dropdown.dropdown-language-compact button:focus,
.dropdown.dropdown-language-compact button:focus-within {
    // box-shadow: 0 0 0 3px rgba($focus-color, 1);
    outline: $focus-color auto 1px;
    border-radius: 0;
}

.dropdown-language-compact .dropdown-item {
    font-size: 1.5rem;
    color: $color-dark;
}

.dropdown-language-compact .dropdown-menu {
    background-color: $color-bright;
    border: 2px solid rgba($color-dark, 0.5);
    border-radius: 0;
}

// .dropdown-item:hover, .dropdown-item:focus {
//     background-color: rgba($color-dark, 0.1);
// }
