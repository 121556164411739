$btn_background_color: $color-light;
$btn_background_color_hover: $color-dark;
$btn_border_color: $color-dark;
$btn_border_width: 6px;
$btn_color: $color-dark;
$btn_color_hover: $color-light;
$btn_shadow: 0px 4px 13px rgba(51, 47, 45, 0.2);

.btn {
    background-color: $btn_background_color;
    border: $btn_border_width solid $btn_border_color;
    box-shadow: $btn_shadow;
    color: $btn_color;
    display: inline-block;
    font-size: 2.1rem;
    font-weight: 700;
    line-height: 1;
    margin: 1rem 0;
    padding: 10px 15px;
    text-decoration: none;
}

.btn:hover {
    background-color: $btn_background_color_hover;
    color: $btn_color_hover;
}

.btn:focus {
    box-shadow: 0 0 0 3px rgba($focus-color, 1);
}

@media only screen and (min-width: $page-width) {
    .btn {
        font-size: 2.4rem;
        padding: 9px 15px;
    }
}

.btn-group {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px 25px;
}

.btn.btn-sm {
    font-size: 1.5rem;
    padding: 7px 10px;
}

.btn.btn-arrow-right {
    background-image: $svg-finger-right;
    background-position: right 15px center;
    background-repeat: no-repeat;
    background-size: 30px 14px;
    padding-right: 61px;
}

.btn.btn-arrow-right:hover {
    background-image: $svg-finger-right-white;
}

.btn.btn-arrow-left {
    background-image: $svg-finger-left;
    background-position: left 15px center;
    background-repeat: no-repeat;
    background-size: 30px 14px;
    padding-left: 61px;
}

.btn.btn-arrow-left:hover {
    background-image: $svg-finger-left-white;
}

@media only screen and (min-width: $page-width) {
    .btn.btn-arrow-right {
        background-size: 45px 21px;
        padding-right: 77px;
    }

    .btn.btn-arrow-left {
        background-size: 45px 21px;
        padding-left: 77px;
    }
}

body.drink .btn {
    background-color: var(--drink-base-color, $color-yellow);
}

.theme-light .btn,
body .btn.btn-light {
    background-color: $color-light;
}

.theme-light .btn:hover,
body .btn.btn-light:hover {
    background-color: $btn_background_color_hover;
    color: $btn_color_hover;
}

.theme-yellow .btn,
body .btn.btn-yellow {
    background-color: $color-yellow;
}

.theme-yellow .btn:hover,
body .btn.btn-yellow:hover {
    background-color: $btn_background_color_hover;
    color: $btn_color_hover;
}

.theme-greenblue .btn,
.btn.btn-greenblue {
    background-color: $color-greenblue;
}

.theme-greenblue .btn:hover,
.btn.btn-greenblue:hover {
    background-color: $btn_background_color_hover;
    color: $btn_color_hover;
}

.theme-pink .btn,
.btn.btn-pink {
    background-color: $color-pink;
}

.theme-pink .btn:hover,
.btn.btn-pink:hover {
    background-color: $btn_background_color_hover;
    color: $btn_color_hover;
}

.theme-purple .btn,
.btn.btn-purple {
    background-color: $color-purple;
}

.theme-purple .btn:hover,
.btn.btn-purple:hover {
    background-color: $btn_background_color_hover;
    color: $btn_color_hover;
}

.btn.btn-dark {
    background-color: $color-dark !important;
    color: $color-light;
}

.btn.btn-arrow-right.btn-dark {
    background-image: $svg-finger-right-white;
}

.btn.btn-untappd {
    background-image: $svg-icon-untappd;
    background-position: right 15px center;
    background-repeat: no-repeat;
    background-size: 32px 32px;
    padding-right: 58px;
}

.btn.btn-untappd:hover {
    background-image: $svg-icon-untappd-white;
}

.btn-side  {
    padding: 0 $col_margin;
    position: relative;
}

.btn-side::before {
    content: "";
    display: block;
    border-bottom: $btn_border_width solid $btn_border_color;
    left: 0;
    top: 50%;
    margin-top: -3px;
    position: absolute;
    width: $col_margin;
}

.btn-side .btn {
    position: relative;
    z-index: 1;
}

.drink .btn-side.btn-side-bottom {
    margin-top: 80px;
}

@media only screen and (min-width: $page-width) {
    .btn-side  {
        padding: 0 50px;
    }

    .btn-side::before {
        width: 50px;
    }
}

.btn.btn-settings {
    background-color: transparent;
    border: 1px solid $color-dark;
    border-radius: 50em;
    box-shadow: none;
    color: inherit;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 5px 10px;
}

.btn.btn-settings:hover {
    border-color: $color-dark;
    color: $color-light;
}